








import { defineComponent } from '@vue/composition-api';
import Cards from './Cards.vue';

export default defineComponent({
  components: { Cards },
});
